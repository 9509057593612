export const PIPELINES = "pipelines";
export const PIPELINE_ITEMS = "pipeline-items";
export const PIPELINE_NODES = "pipeline-nodes";
export const PIPELINE_JOBS = "pipeline-jobs";
export const PIPELINE_JOB_SEQUENCES = "pipeline-job-sequences";
export const PIPELINE_FLOWS = "pipeline-flows";
export const PIPELINE_GROUPS = "pipeline-groups";
export const PIPELINE_GROUP_TAGS = "pipeline-group-tags";
export const DOWNSTREAM = "downstream";
export const UPSTREAM = "upstream";
export const LINKED_INFO = "linked-info";
export const LINKED_NODE = "linked-node";
export const TRANSFORMATION_TEST = "transformation-test";
export const ONE = "one";

// Conditions
export const PIPELINE_NODE_CONDITIONS = "pipeline-node-conditions";

export const CONDITION_SUBJECT_FILES = "files";
export const CONDITION_SUBJECT_ITEMS = "items";

export const PIPELINE_BATCH_PROCESS = "pipeline-batch-process";
export const PIPELINE_BATCH_PROCESS_JOBS = "batch-process-jobs";

export const CONDITION_PROPERTY_QUANTITY = "quantity";
export const CONDITION_PROPERTY_TITLE = "title";
export const CONDITION_PROPERTY_DATE = "date";
export const CONDITION_PROPERTY_SIZE = "size";
export const CONDITION_PROPERTY_FORMAT = "format";

export const CONDITION_COMPARATOR_GREATER_THAN = "greater_than";
export const CONDITION_COMPARATOR_GREATER_THAN_OR_EQUALS =
  "greater_than_or_equals";
export const CONDITION_COMPARATOR_LESS_THAN = "less_than";
export const CONDITION_COMPARATOR_LESS_THAN_OR_EQUALS = "less_than_or_equals";
export const CONDITION_COMPARATOR_CONTAINS = "contains";
export const CONDITION_COMPARATOR_BEFORE = "before";
export const CONDITION_COMPARATOR_AFTER = "after";
export const CONDITION_COMPARATOR_EQUALS = "equals";

export const DOWNSTREAM_PIPELINES = "downstream-pipelines";
export const UPSTREAM_PIPELINE_NODES = "upstream-pipeline-nodes";
export const UPSTREAM_FEATURED_THREADS = "upstream-featured-threads";

// API actions
export const UPDATE_STATUS = "update-status";
export const DUPLICATE = "duplicate";

export const PIPELINE_STATUS_ON = "on";
export const PIPELINE_STATUS_SINGLE = "single";
export const PIPELINE_STATUS_EVENT = "event";
export const PIPELINE_STATUS_OFF = "off";

export const PIPELINE_QUEUE_STATUS_PENDING = "pending";
export const PIPELINE_QUEUE_STATUS_READY = "ready";
export const PIPELINE_QUEUE_STATUS_PROCESS = "process";
export const PIPELINE_QUEUE_STATUS_ERROR = "error";

export const PIPELINE_DATA_STATUS_ACCEPT = "accept";
export const PIPELINE_DATA_STATUS_CONTEXT = "context";
export const PIPELINE_DATA_STATUS_ISOLATE = "isolate";

export const PIPELINE_JOB_STATUS_PROCESS = "process";
export const PIPELINE_JOB_STATUS_COMPLETED = "completed";
export const PIPELINE_JOB_STATUS_SKIPPED = "skipped";
export const PIPELINE_JOB_STATUS_WAIT = "wait";
export const PIPELINE_JOB_STATUS_FAILED = "failed";
export const PIPELINE_JOB_STATUS_PARTIAL_FAILURE = "partial_failure";
export const PIPELINE_JOB_STATUS_BATCH = "batch";

// export const PIPELINE_NODE_STATUS_PENDING = 'pending'
// export const PIPELINE_NODE_STATUS_RUNNING = 'running'
// export const PIPELINE_NODE_STATUS_ERROR = 'error'
// export const PIPELINE_NODE_STATUS_COMPLETED = 'completed'
// export const PIPELINE_NODE_STATUS_SKIPPED = 'skipped'
// export const PIPELINE_NODE_STATUS_BATCH_PROCESS = 'batch_process'
// export const PIPELINE_NODE_STATUS_UPSTREAM_BATCH_PROCESS =
//   'upstream_batch_process'

export const PIPELINE_NODE_REPORT_STATUS_OTHER = "other";
export const PIPELINE_NODE_REPORT_INITIALIZATION_ID = "initialization_id";
export const PIPELINE_NODE_REPORT_STATUS_PIPELINE_NODE_MISSING =
  "pipeline_node_missing";
export const PIPELINE_NODE_REPORT_STATUS_OK = "ok";
export const PIPELINE_NODE_REPORT_STATUS_WAIT = "wait";
export const PIPELINE_NODE_REPORT_STATUS_SKIP = "skip";
export const PIPELINE_NODE_REPORT_STATUS_THROW_ERROR = "throw_error";
export const PIPELINE_NODE_REPORT_STATUS_DISABLED = "disabled";
export const PIPELINE_NODE_REPORT_STATUS_BATCH_PROCESS = "batch_process";
export const PIPELINE_NODE_REPORT_STATUS_UPSTREAM_DISABLED =
  "upstream_disabled";
export const PIPELINE_NODE_REPORT_STATUS_UPSTREAM_BATCH_PROCESS =
  "upstream_batch_process";
export const PIPELINE_NODE_REPORT_STATUS_GENERAL_ERROR = "general_error";
export const PIPELINE_NODE_REPORT_STATUS_UPSTREAM_ERROR = "upstream_error";
export const PIPELINE_NODE_REPORT_STATUS_CONDITION_FAILED = "condition_failed";
export const PIPELINE_NODE_REPORT_STATUS_ACTION_NOT_FOUND = "action_not_found";
export const PIPELINE_NODE_REPORT_STATUS_UPSTREAM_CONDITION_FAILED =
  "upstream_condition_failed";
export const PIPELINE_NODE_REPORT_SAMPLE_SIZE = 20;

// FIELD_NAMES of nodes
export const FIELD_TITLE = "title";
export const FIELD_CONTENT = "content";
export const FIELD_PROSPECTIVE_TITLE = "prospectiveTitle";
export const FIELD_PROSPECTIVE_CONTENT = "prospectiveContent";

// TYPES of providers
export const TYPE_CREATE_ENTRY = "creation";
export const TYPE_API_AUTH = "api_auth";
export const TYPE_API_PUBLIC = "api_public";
export const TYPE_NOTIFICATION = "notification";
export const TYPE_PUBLISH = "publish";

// MODE of create-entry nodes
export const MODE_AUTO = "auto";
export const MODE_PROMPT = "prompt";

// MODE of email notification nodes
export const MODE_ENTRY = "entry";
export const MODE_ITEMS = "items";
export const MODE_ALL = "all";

// charts
export const MODE_CHART_REPOSITORY = "repository";
export const MODE_CHART_SITE = "site";
export const MODE_CHART_FEATURED_GROUP = "featured_group";

// GROUPS
export const GROUP_AGGREGATE = "aggregate";
export const GROUP_SINGLE = "single";
export const GROUP_DIRECT = "direct";

// SELECT of node items
export const SELECT_ALL = "all";
export const SELECT_FROM_BEGINNING = "from_beginning";
export const SELECT_FROM_END = "from_end";
export const SELECT_MOST_POPULAR = "most_popular";
export const SELECT_LEAST_POPULAR = "least_popular";
export const SELECT_RANDOM = "random";

// SORT of node items
export const SORT_ALPHANUMERIC = "alphanumeric";
export const SORT_NEWEST = "newest";
export const SORT_OLDEST = "oldest";
export const SORT_RANDOM = "random";

// ACTION of nodes
export const ACTION_GET = "get";
export const ACTION_POST = "post";

// STATUS of nodes
export const STATUS_OK = "ok";
export const STATUS_DISABLED = "disabled";
export const STATUS_ERROR = "error";
export const STATUS_PROMPT = "prompt";

export const RUN_UNIT_MINUTES = "minutes";
export const RUN_UNIT_HOURS = "hours";
export const RUN_UNIT_DAYS = "days";
export const RUN_UNIT_WEEKS = "weeks";
export const RUN_UNIT_MONTHS = "months";

export const MOST_RECENT = "most-recent";
export const SUCCESS_RATIO = "success-ratio";
export const SEQUENCE_RESULT = "sequence-result";
export const SEQUENCE_RESULT_FULL = "sequence-result-full";

export const PIPELINE_NODE_REPORT_LOGS = "pipeline-node-report-logs";
