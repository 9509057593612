import { Observable } from "rxjs";
import { PaginationResponse } from "@datorama/akita";
import {
  Pipeline,
  PipelineBatchProcess,
  PipelineFlow,
  PipelineNode,
  PipelineNodeCondition,
} from "wordparrot-types";
import { map, mergeMap, tap } from "rxjs/operators";
import partition from "lodash-es/partition";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  PAGINATION_SEARCH_PARAM,
  _delete,
  get,
  post,
  put,
} from "lib/api";
import {
  DownstreamPipelineState,
  PipelineFlowState,
  PipelineState,
  UpstreamFeaturedThreadState,
  UpstreamPipelineNodeState,
} from "state/session/interface";
import { FeaturedThread } from "state/featured-group/interface";
import { Tree, TreeChild } from "state/pipeline/interface";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";
import {
  pipelineFlowStore,
  pipelineStore,
  upstreamFeaturedThreadStore,
} from "state/pipeline/store";
import { sessionService } from "state/session/service";
import { timeService } from "services/Time";

import * as pipelineConstants from "constants/pipelines";

export class PipelineService {
  fetch(config: PipelineState): Observable<PaginationResponse<Pipeline>> {
    let url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.search) {
      url += `&${PAGINATION_SEARCH_PARAM}=${encodeURIComponent(config.search)}`;
    }

    if (config.pipelineGroupId) {
      url += `&pipelineGroupId=${config.pipelineGroupId}`;
    }

    if (config.queueStatus) {
      url += `&queueStatus=${config.queueStatus}`;
    }

    if (config.includeNodes) {
      url += `&includeNodes=true`;
    }

    return get<PaginationResponse<Pipeline>>(url).pipe(
      tap((paginationResponse) => {
        pipelineStore.upsertMany(paginationResponse.data);
        sessionService.updatePipelines({
          total: paginationResponse.total,
          lastPage: paginationResponse.lastPage,
        });
        breadcrumbService.set(paginationResponse.data, "pipeline");
      }),
    );
  }

  fetchOne(id: string) {
    return get<ApiResponse<Pipeline>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${id}`,
    ).pipe(
      tap((response) => {
        pipelineStore.upsertMany([response.data]);
        breadcrumbService.set([response.data], "pipeline");
      }),
    );
  }

  create(body: Pipeline): Observable<string> {
    return post<ApiResponse<string>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  update(body: Pipeline): Observable<ApiResponse<void>> {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${
        body.id || ""
      }`,
      {
        body,
      },
    );
  }

  updateStatus(config: { pipeline: Pipeline; status: string }) {
    const { pipeline, status } = config;
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipeline.id}/${pipelineConstants.UPDATE_STATUS}`,
      {
        body: {
          status,
        },
      },
    ).pipe(map((response) => response.data));
  }

  delete(body: Pipeline): Observable<unknown> {
    return _delete<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.id}`,
    ).pipe(
      map((response) => response.data),
      tap(() => pipelineStore.remove([body.id])),
    );
  }

  createNode(body: PipelineNode): Observable<any> {
    return post<ApiResponse<string>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_NODES}`,
      {
        body,
      },
    ).pipe(
      map((response) => response.data),
      mergeMap(() => this.fetchOne(body.pipelineId)),
    );
  }

  createLinkedNode(body: {
    pipelineId: string;
    parentNodeId: string;
    linkedNodeId: string;
  }): Observable<any> {
    return post<ApiResponse<string>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_NODES}/${pipelineConstants.LINKED_NODE}`,
      {
        body,
      },
    ).pipe(
      map((response) => response.data),
      mergeMap(() => this.fetchOne(body.pipelineId)),
    );
  }

  updateNode(body: PipelineNode): Observable<void> {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_NODES}/${body.id}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  updateNodeStatus(body: {
    pipelineId: string;
    pipelineNodeId: string;
    status: string;
  }): Observable<void> {
    return put<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_NODES}/${body.pipelineNodeId}/${pipelineConstants.PIPELINE_NODE_REPORT_STATUS_SKIP}`,
      {
        body,
      },
    ).pipe(map((response) => response.data));
  }

  deleteNode(body: PipelineNode): Observable<void> {
    return _delete<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_NODES}/${body.id}`,
    ).pipe(map((response) => response.data));
  }

  duplicate(pipelineId: string): Observable<void> {
    return post<ApiResponse<void>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipelineId}/${pipelineConstants.DUPLICATE}`,
      {
        body: {},
      },
    ).pipe(map((response) => response.data));
  }

  buildTree(nodes: PipelineNode[]): Tree {
    const sortedNodes = timeService.sorted(nodes, "createdAt", "asc");
    const [nodesWithoutParents, nodesWithParents] = partition(
      sortedNodes,
      (node) => !node.parentNodeId,
    );
    const tree = {
      children: nodesWithoutParents.map((node, index) => {
        const nodeIndex = `${index + 1}`;
        return {
          id: node.id || "",
          parentNodeId: null,
          nodeIndex,
          node,
          children: this.buildTreeRecursive(
            node.id || "",
            nodeIndex,
            nodesWithParents,
            [],
          ),
        };
      }),
    };
    return tree;
  }

  private buildTreeRecursive(
    parentNodeId: string,
    parentNodeIndex: string,
    nodes: PipelineNode[],
    children: TreeChild[],
  ) {
    if (!nodes.length) {
      return children;
    }

    let [nodesMatchingParent, nodesNotMatchingParent] = partition(
      nodes,
      (node) => node.parentNodeId === parentNodeId,
    );

    if (!nodesMatchingParent.length) {
      return children;
    }

    nodesMatchingParent = timeService.sorted(
      nodesMatchingParent,
      "createdAt",
      "asc",
    );

    return nodesMatchingParent.map((node, index) => {
      const nodeIndex = `${parentNodeIndex}.${index + 1}`;
      return {
        id: node.id,
        parentNodeId,
        nodeIndex,
        node,
        children: this.buildTreeRecursive(
          node.id || "",
          nodeIndex,
          nodesNotMatchingParent,
          [],
        ),
      };
    });
  }

  findSelectedChildren(tree: Tree, nodeId: string): TreeChild[] {
    return this.findSelectedChildrenRecursive(tree.children, nodeId) || [];
  }

  private findSelectedChildrenRecursive(
    children: TreeChild[],
    nodeId: string,
  ): TreeChild[] | null {
    if (this.hasMatchingChild(children, nodeId)) {
      return children;
    }

    let result;

    for (let i = 0; i < children.length; i++) {
      result = this.findSelectedChildrenRecursive(children[i].children, nodeId);
      if (result) {
        return result;
      }
    }

    return null;
  }

  private hasMatchingChild(children: TreeChild[], nodeId: string): boolean {
    return children.filter((child) => child.id === nodeId).length > 0;
  }

  // getChildrenWithUnion(treeChild: TreeChild): [Record<string, TreeChild[]>, TreeChild[]] {
  //   let record = {}
  //   let children = treeChild.children
  //   children.forEach(child => {
  //     if (!child.children.length) {
  //     child.children.forEach((subChild) => {
  //       if (record[child.id]) {
  //         record[subChild.id] = uniqBy(concat([child]), 'id')
  //       } else {
  //         record[child.id] = [child]
  //       }
  //     })
  //   })
  //   return [record, children]
  // }

  testTransformations(body: { input: any; transformations: any }) {
    const url = `${domainService.apiRoot}/${pipelineConstants.TRANSFORMATION_TEST}`;
    return post<ApiResponse<any>>(url, { body }).pipe(
      map((response) => response.data),
    );
  }
}

export const pipelineService = new PipelineService();

export class DownstreamPipelineService {
  fetchUpstreamPipelineNodesByPipeline(
    config: UpstreamPipelineNodeState,
  ): Observable<PaginationResponse<PipelineNode>> {
    let url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${config.pipelineId}/${pipelineConstants.PIPELINE_NODES}/${pipelineConstants.UPSTREAM}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<PipelineNode>>(url);
  }

  fetchDownstreamPipelinesByPipelineNode(
    config: DownstreamPipelineState,
  ): Observable<PaginationResponse<Pipeline>> {
    let url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_NODES}/${config.pipelineNodeId}/${pipelineConstants.DOWNSTREAM}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<Pipeline>>(url);
  }

  addDownstreamPipeline(body: {
    pipelineNodeId: string;
    pipelineId: string;
  }): Observable<void> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_NODES}/${body.pipelineNodeId}/${pipelineConstants.DOWNSTREAM}`;

    return post<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  deleteDownstreamPipeline(body: {
    pipelineNodeId: string;
    pipelineId: string;
  }): Observable<void> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_NODES}/${body.pipelineNodeId}/${pipelineConstants.DOWNSTREAM}/${body.pipelineId}`;

    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const downstreamPipelineService = new DownstreamPipelineService();

export class UpstreamFeaturedThreadService {
  fetch(
    config: UpstreamFeaturedThreadState,
  ): Observable<PaginationResponse<FeaturedThread>> {
    let url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${config.pipelineId}/${pipelineConstants.UPSTREAM_FEATURED_THREADS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    if (config.search) {
      url += `&${PAGINATION_SEARCH_PARAM}=${encodeURIComponent(config.search)}`;
    }

    return get<PaginationResponse<FeaturedThread>>(url).pipe(
      tap((paginationResponse) => {
        upstreamFeaturedThreadStore.upsertMany(paginationResponse.data);
        sessionService.updateUpstreamFeaturedThreads({
          total: paginationResponse.total,
          lastPage: paginationResponse.lastPage,
        });
      }),
    );
  }

  add(body: {
    upstreamFeaturedThreadId: string;
    pipelineId: string;
  }): Observable<void> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.UPSTREAM_FEATURED_THREADS}`;

    return post<ApiResponse<void>>(url, {
      body,
    }).pipe(map((response) => response.data));
  }

  delete(body: {
    upstreamFeaturedThreadId: string;
    pipelineId: string;
  }): Observable<void> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.UPSTREAM_FEATURED_THREADS}/${body.upstreamFeaturedThreadId}`;

    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
    );
  }
}

export const upstreamFeaturedThreadService =
  new UpstreamFeaturedThreadService();

export class PipelineFlowService {
  fetch(
    config: PipelineFlowState,
  ): Observable<PaginationResponse<PipelineFlow>> {
    let url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_FLOWS}`;

    url += `?${PAGINATION_PAGE_PARAM}=${config.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${config.perPage}`;

    if (config.repositoryId) {
      url += `&repositoryId=${config.repositoryId}`;
    }

    if (config.countOnly) {
      url += `&${PAGINATION_COUNT_ONLY_PARAM}=true`;
    }

    return get<PaginationResponse<PipelineFlow>>(url).pipe(
      tap((paginationResponse) => {
        pipelineFlowStore.upsertMany(paginationResponse.data);
        sessionService.updatePipelineFlows({
          total: paginationResponse.total,
          lastPage: paginationResponse.lastPage,
        });
      }),
    );
  }

  fetchItemTotal(pipelineFlowId: string): Observable<number> {
    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINE_FLOWS}/${pipelineFlowId}/${pipelineConstants.PIPELINE_ITEMS}`;

    return get<ApiResponse<number>>(url).pipe(map((response) => response.data));
  }
}

export const pipelineFlowService = new PipelineFlowService();

export class PipelineNodeConditionService {
  fetch(body: {
    pipelineId: string;
    pipelineNodeId: string;
  }): Observable<ApiResponse<PipelineNodeCondition[]>> {
    const { pipelineId, pipelineNodeId } = body;

    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipelineId}/${pipelineConstants.PIPELINE_NODES}/${pipelineNodeId}/${pipelineConstants.PIPELINE_NODE_CONDITIONS}`;

    return get<ApiResponse<PipelineNodeCondition[]>>(url);
  }

  update(body: {
    pipelineId: string;
    pipelineNodeId: string;
    pipelineNodeConditions: PipelineNodeCondition[];
  }): Observable<void> {
    const { pipelineId, pipelineNodeId, pipelineNodeConditions } = body;

    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipelineId}/${pipelineConstants.PIPELINE_NODES}/${pipelineNodeId}/${pipelineConstants.PIPELINE_NODE_CONDITIONS}`;

    return post<ApiResponse<void>>(url, {
      body: { pipelineNodeConditions },
    }).pipe(map((response) => response.data));
  }
}

export const pipelineNodeConditionService = new PipelineNodeConditionService();

export class PipelineBatchProcessService {
  fetch(body: {
    pipelineId: string;
  }): Observable<PaginationResponse<PipelineBatchProcess>> {
    const { pipelineId } = body;

    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipelineId}/${pipelineConstants.PIPELINE_BATCH_PROCESS}`;

    return get<PaginationResponse<PipelineBatchProcess>>(url);
  }

  fetchOne(body: {
    pipelineId: string;
    id: string;
  }): Observable<PipelineBatchProcess> {
    const { pipelineId, id } = body;

    const url = `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${pipelineId}/${pipelineConstants.PIPELINE_BATCH_PROCESS}/${id}`;

    return get<ApiResponse<PipelineBatchProcess>>(url).pipe(map(response => response.data));
  }

  create(body: PipelineBatchProcess): Observable<ApiResponse<null>> {
    return post<ApiResponse<null>>(
      `${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_BATCH_PROCESS}`,
      {
        body,
      },
    )
  }

  delete(body: PipelineBatchProcess): Observable<ApiResponse<null>> {
    return _delete<ApiResponse<null>>(`${domainService.apiRoot}/${pipelineConstants.PIPELINES}/${body.pipelineId}/${pipelineConstants.PIPELINE_BATCH_PROCESS}/${body.id}`);
  }
}

export const pipelineBatchProcessService = new PipelineBatchProcessService();
