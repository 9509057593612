import { History } from "history";
import { Observable, firstValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ForgotPasswordForm, LoginForm, ResetPasswordForm, authService } from "services/Auth";
import { MutationOptions } from "lib/request";
import { _delete } from "lib/api";
import { deviceService } from "services/Capacitor";

import * as loginConstants from "constants/login";
import * as routingConstants from "lib/routing";

declare const history: History;
declare const window: Window;

export const useLoginMutation = (
  options: MutationOptions<LoginForm>,
  config: {
    saveInfo: boolean;
    location: any;
    redirectParam: string | null;
  },
) => {
  return useMutation(
    async (payload) => {
      return firstValueFrom(
        authService.login(payload, config.saveInfo).pipe(
          switchMap(() => {
            if (config.redirectParam) {
              return authService.fetchAuth().pipe(
                tap(() => {
                  window.location.href =
                    config.redirectParam || routingConstants.ROUTE_APP_PREFIX;
                }),
              );
            } else if (deviceService.isMobile) {
              return authService.fetchAuth().pipe(
                tap(() => {
                  history.push(routingConstants.ROUTE_APP_PREFIX);
                }),
              );
            } else {
              window.location.href = routingConstants.ROUTE_APP_PREFIX;
              return of();
            }
          }),
        ),
      );
    },
    {
      ...options,
      onSuccess: async (data, variables, context) => {
        await options.onSuccess?.(data, variables, context);
      },
    },
  );
};

export const useForgotPasswordMutation = (
  options: MutationOptions<ForgotPasswordForm>
) => {
  return useMutation(
    async (payload) => {
      return firstValueFrom(
        authService.forgotPassword(payload)
      );
    },
    {
      ...options,
    },
  );
};

export const useResetPasswordMutation = (
  options: MutationOptions<ResetPasswordForm>
) => {
  return useMutation(
    async (payload) => {
      return firstValueFrom(
        authService.resetPassword(payload)
      );
    },
    {
      ...options,
    },
  );
};
