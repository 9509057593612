import { Observable, of } from "rxjs";
import { UpdateEntityPredicate, withTransaction } from "@datorama/akita";
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";

import { PaginationResponse, PaginatorPlugin } from "@datorama/akita";

import {
  ApiResponse,
  PAGINATION_COUNT_ONLY_PARAM,
  PAGINATION_PAGE_PARAM,
  PAGINATION_PER_PAGE_PARAM,
  _delete,
  get,
  post,
  postMultipart,
  put,
} from "lib/api";
import { HubPlugin, Plugin, PluginFormConfiguration } from "./interface";
import { HubPluginState } from "state/session/interface";
import { PluginStore, hubPluginStore, pluginStore } from "./store";
import { breadcrumbService } from "services/Breadcrumb";
import { domainService } from "services/Domain";

import * as apiConstants from "constants/api";
import * as hubPluginConstants from "constants/hub";
import * as pluginConstants from "constants/plugins";

export class PluginService {
  fetch(): Observable<Plugin[]> {
    const url = `${domainService.apiRoot}/${pluginConstants.PLUGINS}`;

    return get<ApiResponse<Plugin[]>>(url).pipe(
      withTransaction((response) => {
        pluginStore.upsertMany(response.data);
        breadcrumbService.set(response.data, "plugin", "hubPluginId");
      }),
      map((response) => response.data),
    );
  }

  fetchByProvider(config: {
    provider: string | undefined;
  }): Observable<Plugin> {
    let url = `${domainService.apiRoot}/${pluginConstants.PLUGINS}/${pluginConstants.BY_PROVIDER}`;
    url += `?provider=${encodeURIComponent(config.provider || "")}`;

    return get<ApiResponse<Plugin>>(url).pipe(
      map((response) => response.data),
      tap((plugin) => {
        pluginStore.upsertMany([plugin]);
      }),
    );
  }

  toggleActivation(config: {
    name: string;
    hubPluginId: string;
    active: boolean;
    author: string;
  }): Observable<ApiResponse<void>> {
    const url = `${domainService.apiRoot}/${pluginConstants.PLUGINS}`;

    return post<ApiResponse<void>>(url, {
      body: config,
    })
  }

  upload(formData: FormData): Observable<void> {
    const url = `${domainService.apiRoot}/${pluginConstants.PLUGINS_ZIP}`;

    return postMultipart<ApiResponse<void>>(url, {
      body: formData,
    }).pipe(map((response) => response.data));
  }

  delete(config: { name: string; author: string }): Observable<void> {
    const { name, author } = config;

    const url = `${domainService.apiRoot}/${
      pluginConstants.PLUGINS
    }/${encodeURIComponent(config.author + "." + config.name)}`;
    return _delete<ApiResponse<void>>(url).pipe(
      map((response) => response.data),
      tap(() => {
        pluginStore.remove(
          (entity) =>
            entity.title === `${author}.${name}` || entity.title === name,
        );
      }),
    );
  }
}

export const pluginService = new PluginService();

export class HubPluginService {
  fetch(
    hubPluginState: HubPluginState,
  ): Observable<PaginationResponse<HubPlugin>> {
    let url = `${apiConstants.HUB_API_ROOT}/${hubPluginConstants.HUB_PLUGINS}`;
    url += `?${PAGINATION_PAGE_PARAM}=${hubPluginState.currentPage}&${PAGINATION_PER_PAGE_PARAM}=${hubPluginState.perPage}`;
    if (hubPluginState.search) {
      url += `&search=${encodeURIComponent(hubPluginState.search)}`;
    }

    return get<PaginationResponse<HubPlugin>>(url).pipe(
      withTransaction((response) => {
        if (response.data.length) {
          hubPluginStore.upsertMany(response.data);
        }
      }),
    );
  }

  fetchOne(payload: { hubPluginId: string }): Observable<HubPlugin> {
    const url = `${apiConstants.HUB_API_ROOT}/${hubPluginConstants.HUB_PLUGINS}/${payload.hubPluginId}`;
    return get<ApiResponse<HubPlugin>>(url).pipe(
      map((response) => response.data),
      withTransaction((plugin) => {
        hubPluginStore.upsertMany([plugin]);
      }),
    );
  }

  install(config: {
    hubPluginId: string;
    hubPluginVersionId: string;
    version?: string;
  }): Observable<any> {
    const { hubPluginId, hubPluginVersionId, version } = config;

    let url = `${domainService.apiRoot}/${hubPluginConstants.HUB_PLUGINS}/${hubPluginId}/${hubPluginConstants.INSTALL}`;

    if (version && hubPluginVersionId) {
      url += `?version=${version}&hubPluginVersionId=${hubPluginVersionId}`;
    }

    return post<ApiResponse<void>>(url, {
      body: {},
    }).pipe(
      map((response) => response.data),
      switchMap(() => pluginService.fetch()),
    );
  }
}

export const hubPluginService = new HubPluginService();
